import React, { useEffect } from "react";
import Layout from "components/Layout";
import Landing from "components/Landing";
import PropTypes from "prop-types";
import { withRouter } from "next/router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserDetails } from "actions/auth";
import { ShortLinkPropTypes } from "utils/propTypes";
import { fetchTestLink } from "utils/shortLink";
import { PAGE_KEYS, preparePageResponse } from "utils/render";
import { isMalformedValue } from "utils/request";
import { GENERIC_ERROR } from "utils/constants/error";
import { authAndRedirect } from "utils/route";

const Home = (props) => {
  const { shortLink } = props;
  useEffect(() => {
    authAndRedirect(props);
  }, []);

  return <Layout>{() => <Landing shortLink={shortLink} />}</Layout>;
};

Home.propTypes = {
  shortLink: PropTypes.shape(ShortLinkPropTypes),
  actions: PropTypes.shape({
    fetchUserDetails: PropTypes.func
  }),
  router: PropTypes.object
};

export const getServerSideProps = async ({ req }) => {
  const { url } = req;

  if (isMalformedValue(url)) {
    return {
      props: {
        error: GENERIC_ERROR
      }
    };
  } else {
    const data = await fetchTestLink({
      shortId: process.env.DEMO_SHORT_LINK_ID_A,
      apiKey: process.env.PRICE_BLOCS_PLATFORM_KEY
    });

    const pageInput = {
      data,
      keys: PAGE_KEYS.HOME
    };
    const response = preparePageResponse(pageInput);

    return {
      props: response
    };
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchUserDetails
    },
    dispatch
  )
});

export default withRouter(connect(null, mapDispatchToProps)(Home));
